<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <slot name="header"></slot>
          </v-col>
        </v-row>
      </div>
    </header>
    <main class="my-3">
      <List class="wa__large wa__large__page mb-10">
        <div class="profile__container">
          <loading v-if="modelData.loading" class="loading-data" />
          <v-form
              @submit.prevent="updateProfileModel"
              :class="{ 'loading-form': modelData.loading }"
              ref="modelEditForm"
          >
            <div class="px-0 px-md-7 mb-9">
              <SectionTitle :title="`1. ${$_t('basicInfo')}`" class="px-0" />
              <v-row class="mt-3">
                <v-col cols="12" lg="2" class="pr-0 pt-0">
                  <div class="profile__image">
                    <div v-if="!enableCropper">
                      <img
                          :src="form.model.thumbnail"
                          alt="profile image"
                          class="w-100 h-100 rounded my-4"
                      >
                      <block-button
                          text="edit profile image"
                          icon="WMi-pencil"
                          class="w-100"
                          height="30"
                          bg-color="black"
                          color="white"
                          @click.native="()=>{enableCropper=true}"
                      />
                    </div>
                    <div v-else>
                      <ImageCropper
                          class="w-100 h-100 rounded"
                          :url.sync="form.model.thumbnail"
                          v-model="fileForm"
                          alt="profile image"
                          label="profile image"
                          required
                          :rules="firstRulesCropper"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" lg="10">
                  <div class="pl-0 pl-lg-7 mt-5">
                    <div class="row">
                      <v-col class="pt-0 mt-0 col-justify" cols="12" md="6">
                        <div>
                          <v-text-field
                              class="pt-0"
                              :label="$_t('firstName')"
                              color="SonicSilver"
                              v-model="form.first_name"
                              dense
                              outlined
                              required
                              :rules="enLangNameRule"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col class="pt-0 mt-0 col-justify" cols="12" md="6">
                        <div>
                          <v-text-field
                              class="pt-0"
                              :label="$_t('lastName')"
                              color="SonicSilver"
                              v-model="form.last_name"
                              dense
                              outlined
                              required
                              :rules="enLangNameRule"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col class="pt-0 mt-0 col-justify" cols="12" md="6">
                        <div>
                          <v-text-field
                              class="pt-0"
                              :label="$_t('japaneseLastName')"
                              color="SonicSilver"
                              v-model="form.last_name_jpn"
                              dense
                              outlined
                              :rules="jpLangRule"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col class="pt-0 mt-0 col-justify" cols="12" md="6">
                        <div>
                          <v-text-field
                              class="pt-0"
                              :label="$_t('japaneseFirstName')"
                              color="SonicSilver"
                              v-model="form.first_name_jpn"
                              dense
                              outlined
                              :rules="jpLangRule"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col class="pt-0 mt-0 col-justify" cols="12" md="6">
                        <div>
                          <date-input
                              :label="$_t('birthday')"
                              color="SonicSilver"
                              v-model="birthday"
                              :nudgeRight="0"
                              dense
                              outlined
                              required
                              :rules="requiredRules"
                              :max="today"
                          ></date-input>
                        </div>
                      </v-col>
                    </div>
                    <v-row class="mt-0">
                      <v-col cols="12" md="6">
                        <v-autocomplete
                            class="pt-0 mt-0"
                            :label="$_t('nationality')"
                            color="SonicSilver"
                            :items="getEthnics"
                            item-text="name"
                            item-value="id"
                            :value="form.ethnic_id"
                            @change="changeNationality"
                            dense
                            outlined
                            required
                            :rules="requiredRules"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                            class="py-0 mt-0"
                            :items="getGenders"
                            item-text="title"
                            item-value="id"
                            :label="$_t('gender')"
                            color="SonicSilver"
                            v-model="form.gender_id"
                            dense
                            outlined
                            required
                            :rules="requiredRules"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                            class="pt-0 mt-0 multiple"
                            color="SonicSilver"
                            :items="getModelTypes"
                            item-value="id"
                            item-text="name"
                            attach
                            label-chips
                            chips
                            :label="$_t('modelTypes')"
                            multiple
                            v-model="form.model_types"
                            dense
                            outlined
                            required
                            :rules="modelTypesRules"
                            item-color="black"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                                class="mt-1"
                                label
                                small
                                :input-value="data.selected"
                            >
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row
                        class="w-77 mt-0 mx-0 ml-md-n3"
                        v-for="index in form.languagesWithLevel.length"
                        :key="index"
                    >
                      <v-col cols="12" class="pt-1 px-0 px-md-3">
                        <v-card flat class="add__language pb-2">
                          <v-row>
                            <v-col cols="12" md="5" class="py-2 pr-6">
                              <v-autocomplete
                                  color="SonicSilver"
                                  class="my-2"
                                  :label="$_t('language')"
                                  :items="getLanguages"
                                  item-value="id"
                                  item-text="name"
                                  v-model="form.languagesWithLevel[index - 1].id"
                                  dense
                                  outlined
                                  required
                                  :rules="requiredRules"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" class="py-2 pr-6">
                              <v-autocomplete
                                  color="SonicSilver"
                                  class="my-2"
                                  :label="$_t('level')"
                                  item-value="id"
                                  item-text="name"
                                  :items="getLanguageLevels"
                                  v-model="
                                  form.languagesWithLevel[index - 1].level_id
                                "
                                  dense
                                  outlined
                                  required
                                  :rules="requiredRules"
                              ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                                class="
                                pt-0 pt-md-3
                                mb-2
                                text-right
                                pr-6 pr-md-3
                                align-center
                                d-flex
                                justify-end
                              "
                            >
                              <block-button
                                  :text="$_t('remove')"
                                  height="20"
                                  class="px-4 btn-remove"
                                  color="red"
                                  bg-color="bgLightPink"
                                  @click.native="removeLanguageRow(index)"
                              ></block-button>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="w-77 mt-0 mb-3">
                      <v-col cols="12" class="text-right px-0 px-md-3">
                        <block-button
                            :text="$_t('add')"
                            height="20"
                            class="mr-2 px-7 btn-add"
                            color="cyan"
                            bg-color="bgCyan"
                            @click.native="addLanguageRow"
                        ></block-button>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="6" class="text-justify pt-0">
                  <v-textarea
                      rows="2"
                      :label="$_t('jobExperience')"
                      color="SonicSilver"
                      dense
                      outlined
                      class="pt-0 mt-0"
                      v-model="form.model.job_experience"
                      required
                      :rules="requiredRules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="text-justify pt-0">
                  <v-textarea
                      rows="2"
                      :label="'japanese '+$_t('jobExperience')"
                      color="SonicSilver"
                      dense
                      outlined
                      class="pt-0 mt-0"
                      v-model="form.model.job_experience_jpn"
                      required
                      :rules="jpLangNoRequiredRule"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="text-justify pt-0">
                  <v-textarea
                      color="SonicSilver"
                      class="pt-0 mt-0"
                      rows="2"
                      :label="$_t('bio')"
                      v-model="form.bio"
                      dense
                      outlined
                      required
                      :rules="requiredRules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="text-justify pt-0">
                  <v-textarea
                      color="SonicSilver"
                      class="pt-0 mt-0"
                      rows="2"
                      :label="'japanese '+$_t('bio')"
                      v-model="form.bio_jpn"
                      dense
                      outlined
                      required
                      :rules="jpLangNoRequiredRule"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="text-justify pt-0">
                  <v-textarea
                      rows="2"
                      :label="$_t('skills')"
                      color="SonicSilver"
                      class="pt-0 mt-0"
                      dense
                      outlined
                      v-model="form.model.skills"
                      required
                      :rules="requiredRules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="text-justify pt-0">
                  <v-textarea
                      rows="2"
                      :label="'japanese '+$_t('skills')"
                      color="SonicSilver"
                      class="pt-0 mt-0"
                      dense
                      outlined
                      v-model="form.model.skills_jpn"
                      required
                      :rules="jpLangNoRequiredRule"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
            <div class="px-0 px-md-7 mb-9 mt-5">
              <SectionTitle :title="`2. ${$_t('contactInfo')}`" />
              <div class="mt-10 mt-md-5">
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('emailAddress')"
                        v-model="form.email"
                        :disabled="type !== 'manager'"
                        dense
                        outlined
                        color="SonicSilver"
                        required
                        :rules="emailValidation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('cellPhone')"
                        color="SonicSilver"
                        dense
                        outlined
                        v-model="form.cell_phone"
                        type="phone"
                        required
                        :rules="numericalRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('lineId')"
                        dense
                        outlined
                        color="SonicSilver"
                        v-model="form.model.line_id"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="mt-5">
                <v-row>
                  <v-col cols="12" sm="6" md="3" class="py-0 py-md-3">
                    <v-text-field
                        :label="$_t('instagramId')"
                        dense
                        outlined
                        color="SonicSilver"
                        v-model="form.model.instagram_id"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="pb-0 pb-md-3 pt-0 pt-md-3"
                  >
                    <v-switch
                        small
                        color="black"
                        inset
                        class="social__switch swich mt-0 mt-sm-2"
                        v-model="form.model.is_show_follower_count"
                    >
                      <template v-slot:label>{{
                          $_t("showFollowersCount")
                        }}</template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="py-0 py-md-3">
                    <v-text-field
                        :label="$_t('youtubeId')"
                        color="SonicSilver"
                        v-model="form.model.youtube"
                        dense
                        outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-switch
                        small
                        color="black"
                        inset
                        class="social__switch swich mt-0 mt-sm-2"
                        v-model="form.model.is_show_subscribe_count"
                    >
                      <template v-slot:label>{{
                          $_t("showSubscribersCount")
                        }}</template>
                    </v-switch>
                  </v-col>
                </v-row>
              </div>
              <div class="mt-0 mt-md-5">
                <v-row>
                  <v-col cols="12" sm="6" md="3" class="py-0 py-md-3">
                    <v-autocomplete
                        :label="$_t('country')"
                        color="SonicSilver"
                        :items="getCountries"
                        item-value="id"
                        item-text="name"
                        v-model="form.country_id"
                        dense
                        outlined
                        required
                        :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="pb-0 pb-md-3 pt-0 pt-md-3"
                  >
                    <v-switch
                        small
                        color="black"
                        inset
                        class="social__switch swich mt-0 mt-sm-2"
                        v-model="form.model.is_hybrid"
                    >
                      <template v-slot:label>Bi-nationality</template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        :label="$_t('city')"
                        color="SonicSilver"
                        dense
                        outlined
                        v-model="form.city"
                        required
                        :rules="requiredRules"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('state')"
                        color="SonicSilver"
                        dense
                        outlined
                        v-model="form.model.state"
                        required
                        :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="mt-3 mt-md-5">
                <v-row>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('address')"
                        dense
                        outlined
                        color="SonicSilver"
                        v-model="form.model.address"
                        required
                        :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('zipCode')"
                        color="SonicSilver"
                        dense
                        outlined
                        v-model="form.model.zipcode"
                        required
                        :rules="numericalRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-0 pt-md-3">
                    <v-text-field
                        :label="$_t('nearestStation')"
                        color="SonicSilver"
                        dense
                        outlined
                        v-model="form.model.nearest_station"
                        required
                        :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="px-0 px-md-7 mb-9">
              <SectionTitle :title="`3. ${$_t('physicalInfo')}`" />
              <v-row class="mb-2 mt-4 my-md-0 mt-lg-2">
                <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    class="pt-0 pt-md-3 pb-0 mr-lg-16"
                >
                  <v-autocomplete
                      class="pt-0 mt-0"
                      :label="$_t('eye')"
                      color="SonicSilver"
                      dense
                      outlined
                      item-value="id"
                      item-text="name"
                      :items="getEyeColors"
                      v-model="form.model.eye_color_id"
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3 pb-0">
                  <v-autocomplete
                      class="pt-0 mt-0"
                      :label="$_t('hair')"
                      color="SonicSilver"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      :items="getHairColors"
                      v-model="form.model.hair_color_id"
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3">
                  <v-text-field
                      class="pt-0 mt-0 pr-0 pr-lg-16"
                      :label="$_t('weight')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.model.weight"
                      type="number"
                      required
                      :rules="weightRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3">
                  <v-text-field
                      class="pt-0 mt-0 pr-0 pr-lg-16"
                      :label="$_t('height')"
                      dense
                      outlined
                      color="SonicSilver"
                      v-model="form.model.height"
                      type="number"
                      required
                      :rules="heightRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3">
                  <v-text-field
                      class="pt-0 mt-0 pr-0 pr-lg-16"
                      dense
                      outlined
                      :label="$_t('bust')"
                      color="SonicSilver"
                      v-model="form.model.bust"
                      type="number"
                      required
                      :rules="bustRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3">
                  <v-text-field
                      class="pt-0 mt-0 pr-0 pr-lg-16"
                      :label="$_t('hips')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.model.hips"
                      type="number"
                      required
                      :rules="hipsRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3">
                  <v-text-field
                      class="pt-0 mt-0 pr-0 pr-lg-16"
                      :label="$_t('waist')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.model.waist"
                      type="number"
                      required
                      :rules="waistRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="2" class="pt-0 pt-md-3">
                  <v-text-field
                      class="pt-0 mt-0 pr-0 pr-lg-16"
                      :label="$_t('shoes')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.model.shoes"
                      type="number"
                      required
                      :rules="shoeRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" class="text-justify pt-3">
                  <v-textarea
                      rows="3"
                      :label="$_t('tattoo')"
                      dense
                      outlined
                      color="SonicSilver"
                      v-model="form.model.tattoo"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
            <div class="px-0 px-md-7 mb-9">
              <SectionTitle :title="`4. ${$_t('bankInfo')}`" />
              <v-row class="mt-3">
                <v-col
                    class="pt-0 pt-md-3 pb-2 py-lg-3"
                    cols="12"
                    md="6"
                    lg="2"
                >
                  <v-text-field
                      class="pt-0"
                      :label="$_t('bankName')"
                      dense
                      outlined
                      color="SonicSilver"
                      v-model="form.model.bank_name"
                      required
                      :rules="requiredRules"
                  />
                </v-col>
                <v-col
                    class="pt-0 pt-md-3 pb-2 py-lg-3"
                    cols="12"
                    md="6"
                    lg="2"
                >
                  <v-text-field
                      class="pt-0"
                      :label="$_t('branchName')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.model.branch_name"
                      required
                      :rules="requiredRules"
                  />
                </v-col>
                <v-col
                    class="pt-0 pt-md-3 pb-2 py-lg-3"
                    cols="12"
                    md="6"
                    lg="2"
                >
                  <v-text-field
                      class="pt-0"
                      :label="$_t('accountNo')"
                      color="SonicSilver"
                      v-model="form.model.account_no"
                      dense
                      outlined
                      required
                      :rules="numericalRules"
                  />
                </v-col>
                <v-col
                    class="pt-0 pt-md-3 pb-2 py-lg-3"
                    cols="12"
                    md="6"
                    lg="2"
                >
                  <v-text-field
                      class="pt-0"
                      :label="$_t('accountName')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.model.account_name"
                      required
                      :rules="requiredRules"
                  />
                </v-col>
                <v-col
                    class="pt-0 pt-md-3 pb-2 py-lg-3"
                    cols="12"
                    md="6"
                    lg="2"
                >
                  <v-autocomplete
                      class="pt-0 mt-0"
                      :label="$_t('accountClassifications')"
                      color="SonicSilver"
                      item-value="id"
                      item-text="name"
                      :items="getAccountClassifications"
                      dense
                      outlined
                      v-model="form.model.account_classification_id"
                      required
                      :rules="requiredRules"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-textarea
                      rows="2"
                      :label="$_t('other')"
                      color="SonicSilver"
                      class="pt-0"
                      dense
                      outlined
                      v-model="form.model.other"
                      required
                  ></v-textarea>
                </v-col>
              </v-row>
              <div v-if="!isNationalityJPN" class="mt-10">
                <SectionTitle :title="`5. ${$_t('visaInfo')}`" />
                <v-row class="mt-3">
                  <v-col
                      class="pt-0 pt-md-3 pb-2 py-lg-3"
                      cols="12"
                      md="6"
                      lg="2"
                  >
                    <date-input
                        :label="$_t('validUntil')"
                        color="SonicSilver"
                        :nudgeRight="0"
                        class="pt-0"
                        dense
                        outlined
                        v-model="form.model.valid_until"
                        required
                        :rules="requiredRules"
                    />
                  </v-col>
                  <v-col
                      class="pt-0 pt-md-3 pb-2 py-lg-3"
                      cols="12"
                      md="6"
                      lg="2"
                  >
                    <v-text-field
                        :label="$_t('typeOfVisa')"
                        color="SonicSilver"
                        :nudgeRight="0"
                        class="pt-0"
                        dense
                        outlined
                        v-model="form.model.type_of_visa"
                        required
                        :rules="requiredRules"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="mt-9" v-if="type === 'manager'">
                <SectionTitle
                    :title="`${isNationalityJPN ? 5 : 6}. ${$_t('attributes')}`"
                />
                <div class="border rounded pt-5 py-2 pl-0 pl-md-5 my-2">
                  <v-row v-for="attr in attributes" :key="attr.id">
                    <v-col cols="12" md="2" class="pt-1 pb-1">
                      <div
                          class="
                          attr_title
                          d-flex
                          align-center
                          justify-center justify-md-start
                          h-100
                        "
                      >
                        {{ attr.name }}:
                      </div>
                      <v-divider
                          v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                          class="my-0 mx-2"
                      />
                    </v-col>
                    <v-col cols="12" md="10" class="pl-8 pt-1 pb-1">
                      <v-row>
                        <v-col
                            cols="6"
                            md="2"
                            v-for="val in attr.attributeValues"
                            :key="val.id"
                        >
                          <div class="attributes">
                            <div>
                              <v-switch
                                  @change="changeSwitch(val.id)"
                                  v-model="form.attrValsSwitch[val.id]"
                                  inset
                                  color="cyan"
                                  class="social__switch"
                              >
                                <template v-slot:label>{{ val.name }}</template>
                              </v-switch>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-divider class="mt-10 mb-2"></v-divider>
              <v-row class="flex-dicoration-column-reverse">
                <v-col
                    cols="12"
                    sm="6"
                    class="pt-0 pt-sm-3 text-center text-sm-left"
                >
                  <router-link
                      :to="{
                      name: 'models',
                      params: { locale: this.$_getlocaleParam() },
                    }"
                      class="text-decoration-none"
                  >
                    <block-button
                        text="never mind"
                        height="30"
                        class="btn-prev pl-0"
                        icon="WMi-left-chevron"
                        text-mode="text"
                    ></block-button>
                  </router-link>
                </v-col>
                <v-col cols="12" sm="6">
                  <block-button
                      height="30"
                      class="
                      btn__update__profile
                      ml-5
                      site__button
                      width-200
                      float-right
                    "
                      text="update"
                      type="submit"
                      :loading="loadingRequest"
                  ></block-button>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </div>
      </List>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>

<script>
import {emailValidation,numericalRules,numberRules,modelTypesRules,requiredRules,enLangNameRule,jpLangNoRequiredRule,jpLangRule,weightRules,heightRules,bustRules,waistRules,hipsRules,shoeRules} from "@/mixins/validate"
import { mapGetters, mapActions } from "vuex";
import List from "../components/Global/Section/List.vue";
import SectionTitle from "../components/Global/Section/SectionTitle.vue";
import ImageCropper from "../components/Global/Input/ImageCropper";
import FileRepository from "../abstraction/repository/FileRepository";
import AttributeRepository from "@/abstraction/repository/attributeRepository";
const attributeRepository = new AttributeRepository();
import { makeid } from "@/utils/math";
import { UserStorage } from "@/utils/storage";
const RANDOM_TOKEN = makeid(50);
let fileRepository = new FileRepository();
let DEFAULT_FORM = {
  attrValsSwitch: [],
  attributeValues: [],
  languagesWithLevel: [{ level_id: null, id: null }],
  model: {},
  file_batch_id: RANDOM_TOKEN,
};
export default {
  components: {
    List,
    SectionTitle,
    ImageCropper,
  },
  props: ["modelData"],
  emits: ["updateProfile","updateModelAppended"],
  data: () => ({
    enableCropper: false,
    ethnic_id : null,
    firstRulesCropper: [(v) => !!v || true],
    switchVal: [],
    fileForm: {
      model_name: "model",
      collection_name: "main_image",
      batch_id: RANDOM_TOKEN,
    },
    loadingRequest: false,
    type: UserStorage.get() ? UserStorage.get().type : "model",
    today: new Date().toISOString().slice(0, 10),
    attributes: [],
  }),
  computed: {
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("modelType", ["getModelTypes"]),
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    ...mapGetters("ethnic", ["getEthnics"]),
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("eyeColor", ["getEyeColors"]),
    ...mapGetters("hairColor", ["getHairColors"]),
    ...mapGetters("accountClassification", ["getAccountClassifications"]),
    numericalRules,
    weightRules,
    heightRules,
    bustRules,
    waistRules,
    hipsRules,
    shoeRules,
    numberRules,
    requiredRules,
    modelTypesRules,
    enLangNameRule,
    jpLangRule,
    jpLangNoRequiredRule,
    emailValidation,
    form() {
      if (this.modelData) {
        if (this.$refs.modelEditForm) {
          this.$refs.modelEditForm.resetValidation();
        }
        return {
          ...DEFAULT_FORM,
          ...this.modelData,
        };
      }
      return DEFAULT_FORM;
    },
    birthday: {
      get() {
        return this.form.birthday;
      },
      set(value) {
        this.modelData.birthday = value;
        this.$emit("input", value);
      },
    },
    isNationalityJPN() {
      if (this.ethnic_id) {
        return this.ethnic_id === 1;
      }
      return this.form.ethnic_id === 1;
    },
  },
  methods: {
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("modelType", ["loadModelTypes"]),
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    ...mapActions("ethnic", ["loadEthnics"]),
    ...mapActions("country", ["loadCountries"]),
    ...mapActions("eyeColor", ["loadEyeColors"]),
    ...mapActions("hairColor", ["loadHairColors"]),
    ...mapActions("accountClassification", ["loadAccountClassifications"]),
    changeNationality(ethnic_id) {
      this.modelData.ethnic_id = ethnic_id;
      this.ethnic_id = ethnic_id;
    },
    imageCropperRule() {
      if (this.fileForm.file || this.form.model.thumbnail) {
        return true;
      } else {
        return "This field is required.";
      }
    },
    addLanguageRow() {
      this.form.languagesWithLevel.push({ id: null,level_id: null, level_name:null,level_name_jpn: null, name:null, name_jpn:null });
    },
    removeLanguageRow(index) {
      this.form.languagesWithLevel.splice(index - 1, 1);
      if (this.form.languagesWithLevel.length === 0) {
        this.form.languagesWithLevel.push({ level_id: null, id: null });
      }
    },
    async updateProfileModel() {
      this.firstRulesCropper = [this.imageCropperRule];
      if (!this.$refs.modelEditForm.validate()) {
        return;
      }
      const hasThumbnail =
          this.fileForm.file ||
          (this.form.model.thumbnail &&
              this.form.model.thumbnail !== "https://via.placeholder.com/200x200");
      if (!hasThumbnail) {
        return;
      }
      this.loadingRequest = true;
      let thumbnail = null;
      if (this.fileForm.file) {
        thumbnail = await fileRepository.store(this.fileForm);
      }else{
        if(this.enableCropper) {
          thumbnail = await fileRepository.store({url: this.form.model.thumbnail, ...this.fileForm});
        }
      }

      if(this.form.gender_id!=null){
        this.form.gender = this.getGenders.find(gender=>gender.id===this.form.gender_id)
      }
      if(this.form.ethnic_id!=null){
        this.form.ethnic = this.getEthnics.find(ethnic=>ethnic.id===this.form.ethnic_id)
      }
      this.form.file_batch_id = this.fileForm.batch_id
      // if(this.form.file_batch_id!=this.fileForm.batch_id){
      if(thumbnail!=null) {
        delete this.form.model['thumbnail']
        this.form.model['thumbnail'] = thumbnail.thumbnail
        this.form.model['thumbnail_review'] = thumbnail.thumbnail
      }
      // }


      if(this.form.languagesWithLevel.length>0){
        let languagesWithLevel = this.form.languagesWithLevel;
        // let languages = this.form.languages;
        let level=null;
        let lang=null;
        this.form.languagesWithLevel = []
        this.form.languages = []
        languagesWithLevel.map(language=>{
          if(language!=null) {
            level = this.getLanguageLevels.find(level => level.id === language.level_id)
            lang = this.getLanguages.find(lang => lang.id === language.id)
            this.form.languages.push({
              id:lang.id,
              language_id:lang.id,
              level_id:level.id
            })
            this.form.languagesWithLevel.push({
              id:lang.id,
              level_id:level.id,
              level_name:level.name,
              level_name_jpn:level.name_jpn,
              name:lang.name,
              name_jpn:lang.name_jpn
            })
          }
        })
      }
      this.$emit("updateProfile", this.form);
      this.loadingRequest = false;
    },
    changeSwitch(id) {
      if (this.form.attributeValuesId.includes(id)) {
        const index = this.form.attributeValuesId.indexOf(id);
        this.$delete(this.form.attributeValuesId, index);
      } else {
        this.form.attributeValuesId.push(id);
      }
    },
    ...mapActions("model", ["updateAttrValues"]),
    async loadAttributes() {
      try {
        const response = await attributeRepository.index();
        this.attributes = response;
      } catch (error) {
        return error;
      }
    },
    checkUpdatedData(){
      this.$emit('updateModelAppended')
    },
  },
  created() {
    this.loadModelTypes();
    this.loadGenders();
    this.loadLanguages();
    this.loadLanguageLevels();
    this.loadEthnics();
    this.loadCountries();
    this.loadHairColors();
    this.loadEyeColors();
    this.loadAccountClassifications();
    this.loadAttributes();
  },
  mounted() {
    if(this.form.model.thumbnail==''){
      this.enableCropper = true
    }
  }
};
</script>

<style scoped>
.profile__container {
  position: relative;
}

.profile__image img {
  border: 1px dashed var(--color-gray);
}

.w-77 {
  width: 77%;
}

.add__language {
  border: 1px solid var(--color-light-gray);
  padding: 10px 15px 0 15px;
  border-radius: 5px;
}
.attr_title {
  font-family: "montserrat-light";
  font-size: 22px;
  color: var(--color-black);
}

ul:nth-child(1) li {
  width: 19%;
}

ul:nth-child(2) li:nth-child(1),
ul:nth-child(2) li:nth-child(2) {
  width: 23%;
}

ul:nth-child(2) li:nth-child(3),
ul:nth-child(2) li:nth-child(4) {
  width: 25%;
  margin: 0 3px;
}

ul:nth-child(2) li:last-child {
  margin: 0;
}

ul:nth-child(3) li:first-child {
  width: 47%;
}

.physical li,
.bank li {
  width: 19% !important;
  padding-right: 80px !important;
}

.model__types {
  width: 60% !important;
}

.wa__large {
  position: relative;
  padding: 13px 30px 10px 27px;
}

.btn__update__profile {
  font-family: "Barlow-EL";
  font-size: 20px !important;
  padding: 0 90px !important;
  letter-spacing: 4px !important;
}

.btn-prev {
  font-size: 18px !important;
}

.loading-data {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.loading-form {
  filter: blur(5px);
}

@media screen and (max-width: 1264px) {
  .w-77 {
    width: 100% !important;
  }

  .physical li,
  .bank li {
    width: 49% !important;
    padding-right: 0px !important;
  }

  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 960px) {
  ul {
    flex-wrap: wrap !important;
  }

  ul:nth-child(2) li,
  ul:nth-child(1) li {
    width: 49%;
  }

  ul:nth-child(2) li {
    width: 49% !important;
  }

  ul:nth-child(2) li:last-child,
  ul:nth-child(3) li:last-child {
    margin: 0 !important;
  }

  ul:nth-child(3) li:first-child {
    width: 100% !important;
  }

  ul:nth-child(3) li {
    /* width: 49% !important; */
    margin: 0 3px !important;
  }

  .physical li,
  .bank li {
    width: 100% !important;
    padding-right: 0px !important;
  }

  .btn-prev {
    letter-spacing: 2px !important;
  }

  .btn__update__profile {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 600px) {
  ul {
    margin-bottom: 0 !important;
  }

  ul li {
    width: 100% !important;
  }

  .flex-dicoration-column-reverse {
    flex-direction: column-reverse;
  }

  .btn__update__profile {
    width: 100%;
    letter-spacing: 3px !important;
  }

  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
</style>
<style>
@media screen and (max-width: 1904px) {
  .social__switch.swich .v-input__control .v-input__slot label {
    font-family: "Montserrat-regular" !important;
    font-size: 12px !important;
  }
  /* .wa__large {
    padding: 7px !important;
  } */
}

@media screen and (min-width: 1264px) {
  .col-justify {
    -ms-flex-preferred-size: 0 !important;
    flex-basis: 0 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
    position: relative !important;
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.social__switch .v-input__control .v-input__slot .v-label {
  word-break: break-word !important;
}
</style>
